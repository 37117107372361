<template>
  <div class="payment">
    <div class="p-contain" v-loading="loading">
      <div class="p-hd">
        <div class="h-title">订单提交成功，请尽快付款！</div>
        <!-- <div class="h-times">
          <div class="t-txt">剩余时间：</div>
          <div class="t-countDown">01 小时 11 分钟 00 秒</div>
        </div> -->
      </div>
      <div class="p-main">
        <div class="o-number">
          订单编号: &nbsp; <span>{{ data.order_no }}</span>
        </div>
        <div class="o-money">
          <div class="m-content">
            订单金额: &nbsp; <strong>{{ data.real_amount }}</strong
            >元
          </div>
          <div class="o-details">
            <div class="d-state" @click="isShowDetails">
              <span>订单详情</span>
              &nbsp;
              <i :class="oStatus ? 'fa fa-caret-down' : 'fa fa-caret-up'"></i>
            </div>
          </div>
        </div>
      </div>
      <!-- 商品地址和商品名称展示 -->
      <div class="o-content" v-if="isDetails">
        <div class="c-address">
          <div>
            收货地址:&nbsp;&nbsp;
            <span v-for="(addr, key) in data.merger_code_name" :key="key"
              >{{ addr }}/</span
            >{{ data.address }} &nbsp; 收货人：<span
              >{{ data.accept_name }} {{ data.mobile }}</span
            >
          </div>
        </div>
        <div class="c-goodsName">
          商品名称:<span v-for="(item, index) in data.orderGoods" :key="index"
            >{{ item.goods_name }}<br
          /></span>
        </div>
      </div>
      <!-- 选择支付方式 -->
      <div class="p-type">
        <div class="t-title">选择以下支付方式</div>
        <div class="t-main clearfix">
          <div
            class="p-item"
            :class="ptype == 1 ? 'p-current' : ''"
            @click="paytype(1)"
          >
            <div class="p-weixin">
              <i class="fa fa-weixin"></i>
              &nbsp;
              <span>微信支付</span>
            </div>
          </div>
          <div
            class="p-item"
            :class="ptype == 2 ? 'p-current' : ''"
            @click="paytype(2)"
          >
            <div class="p-alipay">
              <i class="iconfont icon-alipay1"></i>
              &nbsp;
              <span>支付宝支付</span>
            </div>
          </div>
        </div>
        <div class="t-goPay clearfix" @click="topay()">
          <span>去支付</span>
        </div>
      </div>
      <div style="width: 1000px;height: 600px" v-html="alipayForm"></div>
    </div>
  </div>
</template>

<script>
import { PaymentType, aliPayPage } from '@/api/payment'

export default {
  name:"payment",
  data(){
    return{
      oStatus:true,
      isDetails:false,
      current:0,
      ptype:0,
      data:[],
      loading:false,
      alipayForm: '',
    }
  },
  methods: {
    async orderinfo(){
      this.loading = true;
      const {data: res} = await PaymentType(this.id);
      if(res.message=='ok'){
       this.data = res.result;
      }else{
        this.$router.push('/home/error?mes=服务器繁忙，请稍后在试');
        return false;
      }
      this.loading = false;
    },
    isShowDetails(){
      this.oStatus   =! this.oStatus
      this.isDetails =! this.isDetails
    },
    paytype(type){
      this.ptype = type;
    },
    async topay(){
      if(this.ptype==1){
        this.$router.push('/home/wxPay?id='+this.id);
      }
      if(this.ptype==2){
        const res = await aliPayPage(this.id);
        const div = document.createElement('div')
        /* 下面的data.content就是后台返回接收到的数据 */
        div.innerHTML = res.data.result.form
        document.body.appendChild(div)
        document.forms[0].acceptCharset = 'UTF-8'
        document.forms[0].submit()
      }
    }
  },
  created() {
    this.id   = this.$route.query.id;
    if(typeof this.id !== 'number' && isNaN(this.id)){ this.$router.push('/home/error?mes=服务器繁忙，请稍后在试');return false;}
    this.orderinfo()
  }
}
</script>

<style lang="less" scoped>
.payment {
  background: #f9f9f9;
  padding-bottom: 20px;
  min-height: 600px;
  .p-contain {
    width: 1200px;
    // min-height: 1000px;
    margin: 0 auto;
    // border: 1px solid #e1251b;
    .p-hd {
      height: 150px;
      background: url("../../assets/images/orderBg.png") no-repeat;
      background-size: 100% 100%;
      padding: 0px 30px;
      box-sizing: border-box;

      .h-title {
        line-height: 150px;
        font-size: 32px;
        color: #fff;
      }
    }
    .p-main {
      //   height: 182px;
      background-color: #fff;
      padding: 20px 30px;
      //   border: 1px solid #e1251b;
      font-size: 16px;
      .o-money {
        margin-top: 10px;
        display: flex;
        .m-content {
          strong {
            color: #e1251b;
          }
        }
        .o-details {
          margin-left: 200px;
          font-size: 14px;
          color: #67a4ff;
          cursor: pointer;
          &:hover {
            color: #e1251b;
          }
        }
      }
    }

    .o-content {
      height: 80px;
      font-size: 14px;
      border-top: 2px solid #efefef;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      background-color: #fff;
      padding: 0px 30px;
    }
    .p-type {
      height: 260px;
      //   border: 1px solid #e1251b;
      background: #fff;
      margin: 20px 0px 0px;
      padding: 0px 30px;
      //   padding: 20px;
      .t-title {
        height: 60px;
        line-height: 60px;
        font-size: 16px;
        border-bottom: 1px dotted #cecece;
      }
      .t-main {
        .p-item {
          width: 180px;
          height: 60px;
          border: 2px solid #efefef;
          line-height: 60px;
          text-align: center;
          float: left;
          border-radius: 5px;
          margin: 20px 30px 20px 0px;
          font-size: 14px;

          .p-weixin {
            color: #666;
            cursor: pointer;
            i {
              color: #06b259;
            }
          }

          .p-alipay {
            color: #666;
            cursor: pointer;
            i {
              color: #1677ff;
            }
          }

          &:hover {
            border: 2px solid #e1251b;
          }
        }

        .p-current {
          background: url("../../assets/images/opt.png") no-repeat;
          background-position: right bottom;
          border: 2px solid #e1251b;
        }
      }
      .t-goPay {
        width: 180px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        background: #e1251b;
        border-radius: 5px;
        font-size: 16px;
        color: #fff;
        float: right;
        margin-bottom: 20px;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
}
</style>
